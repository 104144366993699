import React from "react";
import logo from '../assets/images/logopng.png';
import icon1 from '../assets/images/icon1.svg'

import { HashLink } from "react-router-hash-link";
const items = ["What we do", "Key project", "Partners", "The Team", "Ready to build? Contact us"]
const navs = [
    {
        name: "What we do",
        id: "#what-we-do-section"
    },
    {
        name: "Key projects",
        id: "#keyprojects"
    },
    {
        name: "Partners",
        id: "#partners"
    },
    {
        name: "The Team",
        id: "#theteam"
    },
    {
        name: "Ready to build? Contact us",
        id: "#contactUs"
    },
]


const NavigationDesktop = () => {

    return (
        <nav className="hidden md:flex md:justify-between lg:px-20 pl120"

        >
            <div>
                <img className='brand' src={logo} />
            </div>
            <div className="flex flex-row"
            >
                {
                    navs.map(it => (
                        <div className="pt-8 pb-8 pointer nav-text"
                            
                        >
                            <span className="text-white">
                                <HashLink smooth to={it.id}>
                                    <span>
                                        {it.name}
                                    </span>
                                </HashLink>

                            </span>
                        </div>
                    ))
                }
            </div>
        </nav>
    )
}

export default NavigationDesktop